export default {
    propertys: [
        'audioTracks',
        'autoplay',
        'buffered',
        'controller',
        'controls',
        'crossOrigin',
        'currentSrc',
        'currentTime',
        'defaultMuted',
        'defaultPlaybackRate',
        'duration',
        'ended',
        'error',
        'loop',
        'mediaGroup',
        'muted',
        'networkState',
        'paused',
        'playbackRate',
        'played',
        'preload',
        'readyState',
        'seekable',
        'seeking',
        'src',
        'startDate',
        'textTracks',
        'videoTracks',
        'volume',
    ],
    methods: ['addTextTrack', 'canPlayType', 'load', 'play', 'pause'],
    events: [
        'abort',
        'canplay',
        'canplaythrough',
        'durationchange',
        'emptied',
        'ended',
        'error',
        'loadeddata',
        'loadedmetadata',
        'loadstart',
        'pause',
        'play',
        'playing',
        'progress',
        'ratechange',
        'seeked',
        'seeking',
        'stalled',
        'suspend',
        'timeupdate',
        'volumechange',
        'waiting',
    ],
    prototypes: [
        'width',
        'height',
        'videoWidth',
        'videoHeight',
        'poster',
        'webkitDecodedFrameCount',
        'webkitDroppedFrameCount',
        'playsInline',
        'webkitSupportsFullscreen',
        'webkitDisplayingFullscreen',
        'onenterpictureinpicture',
        'onleavepictureinpicture',
        'disablePictureInPicture',
        'cancelVideoFrameCallback',
        'requestVideoFrameCallback',
        'getVideoPlaybackQuality',
        'requestPictureInPicture',
        'webkitEnterFullScreen',
        'webkitEnterFullscreen',
        'webkitExitFullScreen',
        'webkitExitFullscreen',
    ],
};
